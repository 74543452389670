<template>
    <div>
        <CCard v-if="showContent">
            <CCardHeader>
                {{ title }}
            </CCardHeader>
            <CCardBody>
                <div class="info">
                    <p><b>Nombre: </b>{{ this.categoryFields.name }}</p>
                    <p><b>Nombre técnico: </b>{{ this.categoryFields.psy_name }}</p>
                    <p><b>Tipo: </b>{{ this.categoryTypeName }}</p>
                </div>
                <div class="button-container">
                    <CButton class="btn btn-secondary" style="margin-right:1em;" @click="back()">
                        Volver
                    </CButton>
                    <button v-if="!showForm && this.$store.state.isAdmin" class="btn btn-show-form" @click="showEditForm">
                        Editar categoría
                    </button>
                </div>
            </CCardBody>
        </CCard>

        <CCard v-if="showForm">
            <CCardHeader>
                {{ formTitle }}
            </CCardHeader>
            <CCardBody>
                <AbstractForm :formContent="formContent" @emit-methods="onEmitMethods" :fields="categoryFields" />
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import { updateCategory, getCategoryData, getCategoriesData } from '@/services/categories';
import { getCategoriesTypeData } from '@/services/category_type';
import AbstractForm from '@/components/Forms/AbstractForm.vue';
import { getFormEditCategory } from "@/utils/forms/categories/form";

export default {
    name: "updateCategory",
    components: { AbstractForm },
    data(){
      return{
        id: null,
        categories: [],
        title: 'Información de la categoría',
        formTitle: "Editar una categoría",
        showForm: false,
        showContent: true,
        categoryTypeName: '',
        categoryFields: {
            name: null,
            psy_name: null,
            type: null
        },
        formContent: getFormEditCategory()
      }
    },
    created() {
        this.id = this.$route.params.id;
        if(this.id){
            this.getCategoriesType()
            this.getCategory()
            this.getCategories();
        } 
    },
    methods: {
        onEmitMethods(event, dataCategory) {
            switch (event) {
                case 'edit': this.editCategory(dataCategory); break;
                case 'cancel': this.restoreContent(); break;
                case 'back': this.back(); break;
                default: break;
            }
        },
        /**
         * Función en la que se obtienen las categorías
         *
         * @returns {object[]} Las categorías obtenidas.
         */
         getCategories() {
            getCategoriesData()
                .catch((error) => { this.$store.state.errorAlert = { status: true, msg: error.toString() };})
                .then((result) => {
                    this.categories = result.filter(category => category.id !== Number(this.id));
                });
        },
        /**
         * Método para poder obtener el tipo de categoría y preseleccionarla en el Select
        */
        questCategoryTypeValue(CategoryTypeId) {
            const categoryType = this.formContent.rows[0].content[2].content.options.find((i) => i.value == CategoryTypeId);
            this.categoryTypeName = categoryType.name
            this.categoryFields.type = categoryType.value;
        },
        /**
         * Función para obtener los datos de la categoría en cuestión
        */
        getCategory() {
            if (!this.id) return;
            let data = getCategoryData(this.id);
            data.catch((error) => { 
                if (error.response && error.response.status === 404){
                    this.$router.push('/404');
                    return
                }
                this.$store.state.errorAlert = { status: true, msg: error.toString() };
            })
            .then((result) => {
                this.categoryFields.name = result.name;
                this.categoryFields.psy_name = result.psy_name;
                this.questCategoryTypeValue(result.type.id);
            })
        },
        /**
         * Método para obtener los tipos de categoría
         */
         getCategoriesType(){
            let data = getCategoriesTypeData();
            data.catch((error) => { this.$store.state.errorAlert = { status: true, msg: error.toString() };})
            .then((result) => {
                const options = result.map(category => {
                    return {
                        name: category.name,
                        value: category.id
                    };
                });
                this.formContent.rows[0].content[2].content.options = options;
            })
        },
        showAlertError(msg) {
            this.$store.state.errorAlert = { status: true, msg: msg.toString() }
        },
        /**
         * Método para validar los inputs del formulario
         */
         checkInputs(dataCategory) {
            const specialCharactersRegex = /^[a-zA-ZáéíóúÁÉÍÓÚ0-9\s]+$/;
            const validationRules = [
                { name: 'Nombre', field: 'name', message: 'Introduzca el nombre (máximo de 255 caracteres).', maxLength: 255 },
                { name: 'Nombre', field: 'name', message: 'El nombre no debe contener caracteres especiales.', regex: specialCharactersRegex },
                { name: 'Tipo de categoría', field: 'type', message: 'Seleccione un tipo de categoría.', value: '' },
                { name: 'Nombre técnico', field: 'psy_name', message: 'Introduzca el nombre técnico (máximo de 255 caracteres).', maxLength: 255 },
                { name: 'Nombre técnico', field: 'psy_name', message: 'El nombre técnico no debe contener caracteres especiales.', regex: specialCharactersRegex },
            ];

            const existingCategory = this.categories.find(category => {
                return category.name.toLowerCase() === dataCategory.name.toLowerCase()
            });
            
            if (existingCategory) {
                this.showAlertError(`El nombre "${dataCategory.name}" ya existe en las categorías.`);
                return false;
            }

            for (const rule of validationRules) {
                const value = dataCategory[rule.field];
                if (value === undefined || value === null) {
                    this.showAlertError(`El campo ${rule.name} está vacío`);
                    return false;
                }
                if(rule.maxLength && value.length > rule.maxLength){
                    this.showAlertError(`El número de caracteres de ${rule.name} debe ser menor o igual al siguiente valor: ${rule.maxLength}`);
                    return false;
                }
                if (!value && value !== 0 || (rule.maxLength && value.length > rule.maxLength) || (rule.minLength && value.length < rule.minLength) || (rule.noSpaces && value.includes(' ')) || (rule.value && value === rule.value) || (rule.minValue && parseInt(value) < rule.minValue) || (rule.equalField && value !== dataCategory[rule.equalField]) || (rule.regex && !rule.regex.test(value))) {
                    this.showAlertError(rule.message);
                    return false;
                }
            }
            return true;
        },
        /**
         * Método para poder editar una nueva categoría
         */
        editCategory(dataCategory) {
            if (this.checkInputs(dataCategory) == false) return;
            updateCategory(dataCategory, this.id)
                .then(() => {
                    this.$router.push("/categories");
                }).catch((error) => {
                    this.$store.state.errorAlert = { status: true, msg: error.toString() } 
                })
        },
        /**
         * Función para mostrar el alert durante 10 segundos.
         */
        showFailureParameters() {
            this.contAlertParams = 10;
        },
         /**
         * Función para mostrar el formulario para editar la categoría
         */
         showEditForm(){
            this.showForm = true;
        },
         /**
         * Función para restaurar el contenido
         */
         restoreContent(){
            this.showContent = true;
            this.showForm = false;
        },
        /**
         * Función para volver atrás
        */
        back() {
            this.$router.push("/categories");
        },
    },
};
</script>